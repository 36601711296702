<script setup lang="ts">
import { computed } from 'vue'
import type { ActivityItemMetadataRefundReasons } from '/~/types/api'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity } from '/~/composables/activity/core/activity'

const ReturnReasonsMap: Record<ActivityItemMetadataRefundReasons, string> = {
  amount_mismatch:
    'Your PayID payment could not be processed because the amount was incorrect. Your funds have been returned.',
  expired:
    'Your PayID payment could not be processed because the PayID payment details had expired by the time the payment was made. Your funds have been returned.',
  cancelled:
    'Your PayID payment could not be processed because the payment was cancelled. Your funds have been returned.',
  order_not_found:
    'Your PayID payment could not be processed because the PayID reference does not match any outstanding payments. Your funds have been returned.',
  duplicate_payment:
    'Your PayID payment could not be processed because the funds have already been received. Your duplicate funds have been returned.',
  payment_failed:
    'We received your PayID payment but the transfer to the payee could not be processed by the payee’s bank. Your funds have been returned.',
  other:
    'Your PayID payment could not be processed. Please contact our support team.',
}

const props = withDefaults(
  defineProps<{
    activity: Activity
  }>(),
  {}
)

const showActivityDetailsLink = computed(
  () =>
    props.activity.metadata?.originalOrderNumber &&
    props.activity.metadata?.reason !== 'other'
)

const status = computed(() =>
  props.activity.isStatusFailed
    ? {
        title: 'Failed',
        icon: 'heroicons/solid/x-circle',
        iconColor: 'text-red-700',
        color: 'text-red-700',
      }
    : {
        title: 'Refunded',
        icon: 'heroicons/solid/information-circle',
        iconColor: 'text-primary',
        color: 'text-emerald-700',
      }
)
</script>

<template>
  <div class="space-y-5 py-4">
    <div class="space-y-5 border-b border-eonx-neutral-400 px-6 pb-8">
      <div class="flex flex-col items-center justify-start self-stretch">
        <base-icon
          v-if="status.icon"
          :svg="status.icon"
          :class="status.iconColor"
          :size="60"
          class="my-3"
        />
        <div
          class="self-stretch text-center text-2xl font-bold leading-loose text-gray-800"
        >
          Payment returned
        </div>
        <div class="flex flex-col items-center justify-start gap-1">
          <div
            class="text-center text-sm font-bold leading-tight text-gray-800"
          >
            Return ID #{{ activity.orderNumber }}
          </div>
          <div class="text-center">
            <span class="text-sm font-bold leading-tight text-gray-800">
              Funds returned:
            </span>
            <span class="text-sm font-normal leading-tight text-gray-800">
              {{ formatDate('daymonthyeartime', activity.createdAt) }}
            </span>
          </div>
          <div
            class="text-center text-sm font-bold leading-tight text-gray-800"
          >
            Status:
            <span :class="status.color">{{ status.title }}</span>
          </div>
        </div>
      </div>
      <div
        class="self-stretch text-base font-normal leading-normal text-slate-900"
      >
        {{ ReturnReasonsMap[activity.metadata.reason] }}
      </div>
      <template v-if="showActivityDetailsLink">
        <div
          class="self-stretch text-base font-normal leading-normal text-slate-900"
        >
          You can view the details of the payment #{{
            activity.metadata.originalOrderNumber
          }}
          below.
        </div>
        <base-link
          :to="{
            name: 'activity-details',
            params: {
              id: String(activity.metadata.originalActivityId),
            },
          }"
          :aria-label="'Order #' + activity.metadata.orderNumber"
          class="inline-block border-b"
        >
          View details
        </base-link>
      </template>
    </div>
    <div class="space-y-2 px-6">
      <div class="flex justify-between">
        <div class="font-bold text-gray-800">Payment returned</div>
        <div>
          {{ formatDollar(activity.total) }}
        </div>
      </div>
      <div class="text-gray-600">
        <!-- TODO: maybe need to make dynamic -->
        Returned by 'EonX Services Pty Ltd'
      </div>
    </div>
  </div>
</template>
